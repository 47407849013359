import { TAnyType } from '@affstore-ds/types'

export const constructUrlWithPayload = (host: string, payload: TAnyType): URL => {
  const url = new URL(host)

  if (!payload) {
    return url
  }

  for (const key in payload) {
    if (payload[key]) {
      url.searchParams.append(key, payload[key])
    }
  }

  return url
}
