import {
  HitTrackerConfigInterface,
  HitTrackerRequestParametersKeysType,
  HitTrackerRequestParametersType,
  TrackerConfigMappingType,
} from './types'
import { TrackerTypesApiPaths } from './types/tracker'

import { constructUrlWithPayload } from './utils/constructUrlWithPayload'
import { getConfigValue } from './utils/getConfigValue'
import { getUrlParameters } from './utils/getUrlParameters'
import { isBrowser } from './utils/isBrowser'
import { removeLastSlash } from './utils/removeLastSlash'

// eslint-disable-next-line import/newline-after-import
;(function () {
  /** Config */
  const defaultConfig: HitTrackerConfigInterface = {
    URLFieldsToAPIFieldsMap: undefined,
    errorCallbackOnHit: console.error,
    host: '',
  }
  let currentConfig: HitTrackerConfigInterface = defaultConfig

  /** Tracked data value */
  const defaultData: HitTrackerRequestParametersType = {
    aff_id: -1,
  }
  let currentData: HitTrackerRequestParametersType = defaultData

  /** Default {[URL param]: API param} mapping */
  const defaultURLFieldsToAPIFieldsMap: TrackerConfigMappingType = {
    advertiser_id: 'advertiser_id',
    aff: 'aff_id',
    aff_model: 'aff_model',
    afftrack: 'aff_track',
    brand_id: 'brand_id',
  }

  /*** Update current tracker data */
  const getTrackedDataValue = (
    newData?: HitTrackerRequestParametersType,
    urlParameters?: URLSearchParams,
  ): HitTrackerRequestParametersType => {
    const newTrackedDataValue: HitTrackerRequestParametersType = defaultData
    const URLFieldsToAPIFieldsMap = { ...defaultURLFieldsToAPIFieldsMap, ...currentConfig.URLFieldsToAPIFieldsMap }

    for (const urlParameter in URLFieldsToAPIFieldsMap) {
      const apiParameter: HitTrackerRequestParametersKeysType = URLFieldsToAPIFieldsMap[urlParameter]

      if (apiParameter) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newTrackedDataValue[apiParameter] =
          newData?.[apiParameter] || // Try to read from newData
          getConfigValue(currentConfig.paramsReader?.[apiParameter]) || // Fallback to read with paramsReader functions
          urlParameters?.get(urlParameter) || // Fallback to read from URL
          undefined
      }
    }

    return newTrackedDataValue
  }

  /** Init config */
  const init = (config: HitTrackerConfigInterface) => {
    currentConfig = {
      ...defaultConfig,
      ...config,
    }

    currentData = getTrackedDataValue(undefined, getUrlParameters())
  }

  /** Send hit */
  const hit = (data?: HitTrackerRequestParametersType, fetchConfig?: RequestInit) => {
    const host = getConfigValue(currentConfig.host)

    if (host === null || host === undefined || typeof host !== 'string') {
      console.error('[AffiliateTracker] Missing host')

      return
    }

    if (!isBrowser()) {
      console.info('[AffiliateTracker] SSR is not supported')

      return
    }

    if (typeof fetch === 'undefined') {
      console.error('[AffiliateTracker] "fetch" is not supported')

      return
    }

    const payload = getTrackedDataValue({ ...currentData, ...data })
    const preparedURL = `${removeLastSlash(host)}${TrackerTypesApiPaths.GetApiTrackerHit}`

    fetch(constructUrlWithPayload(preparedURL, payload).toString(), {
      headers: {
        'Content-Type': 'application/json',
        ...fetchConfig?.headers,
      },
      method: 'GET',
      ...fetchConfig,
    })
      .then((response) => response.json())
      .catch(console.error)
  }

  if (isBrowser()) {
    window.AffiliateHitTracker = {
      hit,
      init,
      /**
       * Current config
       * @readonly
       */
      _readConfig: () => currentConfig,
      /**
       * Current data
       * @readonly
       */
      _readCurrentData: () => currentData,
      /**
       * Default {[URL param]: API param} mapping
       * @readonly
       */
      _readDefaultURLFieldsToAPIFieldsMap: () => defaultURLFieldsToAPIFieldsMap,
    }
  }
})()
